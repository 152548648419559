<!--
 * @FileDescription: 空数据组件
 * @Author: 朱建波
 * @Date: 2023-06-17
 * @LastEditors: 朱建波 342393950@qq.com
 *
 * @name: ZEmpty
 *
 * @Props
 * @property {String} text                 展示文字
 * @property {String} color                文字颜色
 *
 * @Slots
 * footer                                  自定义底部
 *
 * @Methods
-->
<template>
  <div class="z-empty">
    <img class="z-empty-img" src="@/assets/img/nodata.png" :alt="text" />
    <div class="z-empty-text" :style="{ color }">{{ text }}</div>
    <slot name="footer"></slot>
  </div>
</template>

<script>

export default {
  name: 'ZEmpty',
  props: {
    text: { type: String, default: '暂无数据' },
    color: { type: String, default: '' },
  }
}
</script>

<style lang="scss" scoped>
.z-empty {
  padding: 40rem 0 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-img {
    width: 350rem;
    height: 198rem;
  }
  &-text {
    font-weight: 400;
    line-height: 40rem;
    font-size: 14rem;
    color: #ccc;
    user-select: none;
  }
}
</style>

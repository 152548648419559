<template>
  <ZMainPage type="card" :loading="loading1">
    <div class="z-box-bg brl mb-3">
      <div class="top-info">
        <LineWrapper class="list1" primary>
          <LineItem :label="item.label" :value="item.value" :color="item.color" v-for="item in list1" :key="item.label"></LineItem>
        </LineWrapper>
        <el-button v-if="isView" type="primary" @click="handleView(3)">查看审批记录</el-button>
      </div>
    </div>
    <div class="z-box-bg brl">
      <LineWrapper class="list2" primary>
        <LineItem :label="item.label" :value="item.value" v-for="(item, index) in list2" :key="index"></LineItem>
      </LineWrapper>
      <div class="mb-3"></div>
      <DetailTable border :data="tableData1" :config="tableConfig1"></DetailTable>
    </div>
    <Dialog :visible.sync="visible1" title="提示" submitText="通过审核" cancelText="我点错了" buttonAlign="center" titleAlign="center" size="small" :beforeSubmit="handleBeforeSubmit">
      <div class="t-c f-16">审核通过后，家长将根据收费方案缴费</div>
    </Dialog>
    <Dialog :visible.sync="visible2" title="编辑驳回原因" submitText="提交" buttonAlign="center" titleAlign="center" :beforeSubmit="handleBeforeSubmit">
      <el-form ref="form" :model="formData" :rules="rules" labelWidth="150rem" label-position="left">
        <el-form-item class="is-single" label="" prop="remark" labelWidth="0">
          <el-input v-model="formData.remark" placeholder="请输入" type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
    <Dialog :visible.sync="visible3" :loading="loading2" size="big" title="查看审批记录" submitText="我知道了" buttonAlign="center"  :showCancelButton="false">
      <DetailTable border :data="tableData2" :config="tableConfig2"></DetailTable>
    </Dialog>
    <template #footer>
      <template v-if="!isView">
        <el-button type="primary" @click="handleView(1)">通过</el-button>
        <el-button type="danger" plain @click="handleView(2)">驳回</el-button>
      </template>
      <el-button @click="$router.back()">返回</el-button>
    </template>
  </ZMainPage>
</template>
<script>
import Dialog from "@/components/common/Dialog"
import LineWrapper from "@/components/common/LineWrapper"
import LineItem from "@/components/common/LineItem"
import DetailTable from "@/components/common/DetailTable"
import { zsGet, zsPost } from "@/api"
import { zsApproveStatusColor } from "@/utils/data"
export default {
	_config:{"route":[{"path":"detail","meta":{"title":"查看"}}, {"path":"approve","meta":{"title":"审批"}}]},
  components: { Dialog, LineWrapper, LineItem, DetailTable },
  data() {
    return {
      isView: true,
      loading1: false,
      loading2: false,
      isLoaded: false,
      list1: [],
      list2: [],
      tableConfig1: [
        { prop: 'school_year_name', label: '收费学年' },
        { prop: 'charge_date', label: '收费日期' },
        { prop: 'fee_combination_name', label: '科目组合名称' },
        { prop: 'payable_price', label: '应付金额' },
        { prop: 'reduction_price', label: '减免金额' },
        { prop: 'actual_price', label: '实付金额' }
      ],
      tableConfig2: [
        { prop: 'approver', label: '审批人' },
        { prop: 'updated_at', label: '审批时间' },
        { prop: 'approve_status_text', label: '审批结果' },
        { prop: 'remark', label: '备注说明' }
      ],
      tableData1: [],
      tableData2: [],
      visible1: false,
      visible2: false,
      visible3: false,
      formData: { remark: '' },
      rules: {
        remark: [{ required: true, message: '请选择', trigger: 'blur' }],
      }
    }
  },
  created() {
    if(this.$route.path.includes('approve')) {
      this.isView = false
    }
    this.getData()
  },
  methods: {
    getData() {
      this.loading1 = true
      zsGet('/finance/fee-stu/show', { id: this.$route.query.id }).then(res => {
        const { student_name, id_card, approve_status_text, fee_type_text, actual_amount, fee_plan_id, fee_plan: { category_text, mode_text, name } } = res
        this.list1 = [
          { label: "学生姓名", value: student_name },
          { label: "学生身份证号", value: id_card },
          { label: "当前状态", value: approve_status_text, color: zsApproveStatusColor(approve_status_text) }
        ]
        this.list2 = [
          { label: "生活费类型", value: fee_type_text },
          { label: "方案分类", value: category_text },
          { label: "缴费方式", value: mode_text },
          { label: "收费方案", value: name },
          { label: "总金额", value: actual_amount },
        ]
        zsGet('/finance/fee-stu/get-plan', { fee_plan_id }).then(res => {
          const { combination = [] } = res
          this.tableData1 = combination
        }).finally(() => this.loading1=false)
      }).catch(() => this.loading1=false)
    },
    getRecord() {
      if(this.isLoaded) return
      this.loading2 = true
      zsGet('/finance/fee-stu/approve-record', { fee_student_id: this.$route.query.id }).then(res => {
        this.tableData2 = res
        this.isLoaded = true
      }).finally(() => this.loading2=false)
    },
    handleView(type) {
      this['visible' + type] = true
      if(type === 3) this.getRecord()
    },
    handleBeforeAction(done, instance, approve_status) {
      const data = { approve_status, id: this.$route.query.id }
      if(approve_status === 2) data.remark = this.formData.remark
      instance.confirmButtonLoading = true
      zsPost('/finance/fee-stu/approve', data).then(() => {
        done()
        this.$message.success('操作成功')
        this.$router.back()
      }).catch(() => {
        instance.confirmButtonLoading = false
      })
    },
    handleBeforeSubmit(done, instance) {
      if(!this.$refs.form) return this.handleBeforeAction(done, instance, 1)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleBeforeAction(done, instance, 2)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20rem 100rem;
}
.list1 {
  margin: -10rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 20rem;
}
.list2 {
  margin: -10rem 0;
  display: grid;
  gap: 0 20rem;
  grid-template-columns: repeat(3, 1fr);
}
</style>

/**
 * @description 状态类
 */
export const statusColor = {
  已支付: 'text-blue',
  待支付: 'text-red',
  待签约: 'text-purple',
  已签约: 'text-green',
  已取消: 'text-muted',
  签约中: 'text-purple',
  待完善: 'text-orange',
  支付失败: 'text-red',
  已退款: 'text-main',
  待审核: 'text-yellow',
  已通过: 'text-primary',
  已驳回: 'text-red',
}

/**
 * @description 状态颜色
 */
const approveStatusColor = {
  待审核: '#ffc40d',
  已通过: '#1C4EFD',
  已驳回: '#fe3f6c'
}

/**
 * @description 返回状态 类名称
 * @param {string} status_text 状态名字
 * @returns {string}
 */
export const zsApproveStatusClass = (status_text) => {
  return statusColor[status_text] || ''
}
/**
 * @description 返回状态 颜色
 * @param {string} status_text 状态名字
 * @returns {string}
 */
export const zsApproveStatusColor = (status_text) => {
  return approveStatusColor[status_text] || ''
}

